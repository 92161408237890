import React from "react";
import { Link as ScrollLink } from "react-scroll";

export default function NavBar() {
  return (
    <div>
      <nav className="navbar">
        <ul className="navbar_links">
          <li>
            <ScrollLink
              activeClass="active"
              to="about_anchor"
              spy={true}
              smooth={true}
              delay={300}
              offset={-350}
              duration={900}
            >
              about
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              activeClass="active"
              to="bottom_ofpage"
              spy={true}
              smooth={true}
              delay={300}
              offset={-350}
              duration={1000}
            >
              contact
            </ScrollLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
