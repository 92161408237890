import React from "react";
import { Switch, Route } from "react-router-dom";

import About from "./About";
import Inspiration from "./Inspiration";
import Divider from "./Divider";
import Contact from "./Contact";

const year = new Date().getFullYear();

export default function MainPage(props) {
  return (
    <>
      <section id="mainpage">
        <Switch>
          <Route path="/" exact component={About} />
          <Route path="/inspiration" component={Inspiration} />
        </Switch>
        <Divider year={year} />
        <Contact />
      </section>
    </>
  );
}
