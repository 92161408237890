import React from "react";
import styled, { keyframes } from "styled-components";
import { slideInUp } from "react-animations";
import Tree from "../assets/images/tree_589kb.png";
import Birds from "../assets/images/birds-9548.png";

export default function Welcome() {
  
  //'vancouver' and tree intro animation
  const SlideInUp = styled.div`
    animation: 1.4s ${keyframes`${slideInUp}`};
  `;
  return (
    <section id="hero">
      <div className="hero">
        <img className="hero_image-birds" src={Birds} alt="flying birds"></img>
        <h2 className="hero_text-greetings">Greetings</h2>
        <h2 className="hero_text-from">from</h2>
        <SlideInUp className="hero_text-van-animation">
          <h1 className="hero_text-van">Vancouver</h1>
        </SlideInUp>
        <SlideInUp className="hero_image_animation-tree">
          <img className="hero_image-tree" src={Tree} alt="tree branch"></img>
        </SlideInUp>
      </div>
    </section>
  );
}
