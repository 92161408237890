import React from "react";
import "./styles/main.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import MainPage from "./components/MainPage";

export default function App() {
  return (
    <div className="App">
      <Navbar />
      {Hero !== "" ? (
        <Hero />
      ) : (
        <div className="app__delivery-wrapper">
          <h1>&#128235;</h1>
          <h3> Delivering...</h3>
        </div>
      )}
      <MainPage />
    </div>
  );
}
