import React from "react";
import Card from "./Card";
import LogoReact from "../assets/icons/react200.png";
import LogoJS from "../assets/icons/js144.png";
import LogoPHP from "../assets/icons/php150.png";
import LogoNode from "../assets/icons/nodejs200.png";
import LogomySQL from "../assets/icons/mysql150.png";
import LogoMongo from "../assets/icons/mongodb144.png";
import LogoSASS from "../assets/icons/sass144.png";
import LogoCSS from "../assets/icons/css3144.png";
import LogoHTML from "../assets/icons/html144.png";

//Update (CSS) - change 'run' keyframe ranges for new skills @ each viewport
const skills = [
  { name: "React", image: LogoReact },
  { name: "Javascript", image: LogoJS },
  { name: "PHP", image: LogoPHP },
  { name: "NodeJS", image: LogoNode },
  { name: "mySQL", image: LogomySQL },
  { name: "MongoDB", image: LogoMongo },
  { name: "SASS", image: LogoSASS },
  { name: "CSS", image: LogoCSS },
  { name: "HTML", image: LogoHTML },
];

//creates a card for each skill
const displaySkills = skills.map((skill, index) => {
  return (
    <Card key={`${index}+"card"`} title={skill.name} image={skill.image} />
  );
});

export default function About(props) {
  return (
    <section id="skills">
      <div className="skills_wrapper">{displaySkills}</div>
    </section>
  );
}
