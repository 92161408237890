import React from "react";

export default function Divider(props) {
  return (
    <div className="about_divider">
      <p className="about_divider_text">
        Printed by davidthemavid {props.year} &copy;{" "}
      </p>

      <div className="about_divider_line"></div>
      <p className="about_divider_text">
        Printed on Digital Paper - Non Toxic Pixels -{" "}
      </p>
      <div className="about_divider_madein">
        <span className="red">Made</span> <span className="white">in </span>
        <span className="red">Canada</span>
      </div>
    </div>
  );
}
