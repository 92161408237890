import React from "react";
import Skills from "./Skills";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import DescriptionIcon from "@material-ui/icons/Description";
import GitHubIcon from "@material-ui/icons/GitHub";

export default function Contact() {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="contact_link_container">
      <Skills />
      <p className="contact_link_name">David Gold</p>
      <p className="contact_link_title">web developer</p>
      <div className="contact_link_wrapper">
        <AlternateEmailIcon></AlternateEmailIcon>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:info@davidthemavid.com"
        >
          <p>email</p>
        </a>
      </div>
      <div className="contact_link_wrapper">
        <LinkedInIcon>
          <></>
        </LinkedInIcon>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/dtgold"
        >
          <p>linkedin</p>
        </a>
      </div>
      <div className="contact_link_wrapper">
        <DescriptionIcon></DescriptionIcon>
        <Link
          download
          target="_blank"
          rel="noopener noreferrer"
          to="/resume2.pdf"
        >
          <p>resume</p>
        </Link>
      </div>
      <div className="contact_link_wrapper">
        <GitHubIcon></GitHubIcon>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/davidthemavid"
        >
          <p>github</p>
        </a>{" "}
      </div>
      <div className="contact_b2top_wrapper">
        <ScrollLink
          activeClass="active"
          to="navbar"
          spy={true}
          smooth={true}
          delay={300}
          duration={900}
          onSetActive={() =>
            location.pathname !== "/" ? history.goBack() : null
          }
        >
          <p className="contact_b2top_button">Return to Sender </p>
        </ScrollLink>
      </div>
      <span className="bottom_ofpage"></span>
    </div>
  );
}
