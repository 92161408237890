import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Me from "../assets/images/me_133kb.jpg";

export default function About() {
  const [pStyle, setPStyle] = useState({ opacity: "0.8" });
  const [imgStyle, setImgStyle] = useState({ opacity: "0" });

  const pOnDown = { opacity: "0", transition: "opacity 0.5s ease-in" };
  const pOnUp = { opacity: "0.8", transition: "opacity 2s ease-in" };

  const imgOnDown = {
    opacity: "1",
    transition: "opacity 2s ease-in zoom 4s ease-in",
    filter: "drop-shadow(8px 8px 10px gray) blur(0.5px)",
    display: "block",
  };
  const imgOnUp = {
    opacity: "0",
    transition: "opacity 4s ease-in",
  };

  //state for setting style based off scroll position
  const [aboutStyle, setAboutStyle] = useState({
    transition: "all 200ms ease-in",
  });

  //initiates animation for scroll
  useScrollPosition(
    ({ prevPos, currPos }) => {
      let viewPort = window.screen.width;
      // console.log("Viewport width: " + viewPort);
      // console.log("Y position: " + currPos.y);

      //sets elements style based off user's vertical mouse position on screen
      const isVisible = currPos.y < -400 && currPos.y > -1000 ? true : false;

      const scrollStyle = {
        visibility: isVisible ? "visible" : "hidden",
        opacity: isVisible ? 1 : 0,
        transition: `all 600ms ${isVisible ? "ease-in-out" : "ease-out"}`,
        transform: isVisible ? "none" : "scale(0.8)",
      };

      if (JSON.stringify(scrollStyle) === JSON.stringify(aboutStyle)) return;

      setAboutStyle(scrollStyle);
    },
    [aboutStyle]
  );

  return (
    <div className="about_container" style={{ ...aboutStyle }}>
      <h1 className="about_container_header">Dear friend,</h1>
      <img
        style={imgStyle}
        src={Me}
        className="about_container_image"
        alt="me seattle"
      ></img>
      <div style={pStyle} className="about_container_text_wrapper">
        <p>
          welcome to my little home on the web. I thought I would at least share
          the view.
        </p>
        <p>
          My fascination with technology led me to work for a startup focused on
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://en.wikipedia.org/wiki/Shared_mobility"
          >
            {" "}
            shared mobility
          </a>
          , launching car-share programs for{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://gigcarshare.com/"
          >
            AAA
          </a>
          ,{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://evo.ca/">
            BCAA
          </a>
          , and{" "}
          <a
            className="about_anchor"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.seattletimes.com/business/technology/bmw-wants-to-share-in-seattles-car-sharing-boom/"
          >
            BMW
          </a>
          . It was through the use and testing of our company's proprietary app
          that I experienced my first taste of what development could offer.
        </p>
        <p>
          Witnessing the impact our app had on company operations & growth led
          <span
            className="about_container_span_animation"
            onMouseDown={() => setPStyle(pOnDown, setImgStyle(imgOnDown))}
            onMouseUp={() => setPStyle(pOnUp, setImgStyle(imgOnUp))}
          >
            {" "}
            me
          </span>{" "}
          to pursue web development as a career. I graduated BrainStation's web
          development program in 2019 with the aim of enabling other small
          businesses the ability to achieve their goals through technology.{" "}
        </p>
      </div>
      <Link className="about_more_wrapper" to="/inspiration">
        <h3 className="about_more_button">more...</h3>
      </Link>
    </div>
  );
}
