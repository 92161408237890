import React from "react";

export default function Card(props) {
  return (
    <div id="card" key={`${props.index}+"card"`}>
      <div className="card_container">
        <h3 className="card_title">{props.title}</h3>
        <img className="card_icon" src={props.image} alt={props.title}></img>
      </div>
    </div>
  );
}
