import React from "react";
import { Link } from "react-router-dom";

export default function Inspire() {
  return (
    <section id="inspiration">
      <div className="inspiration_wrapper">
        <h1 className="inspiration_header">Inspiration</h1>
        <p>
          {" "}
          Focusing on a specific subject inspired and simplified the direction
          of my design. It helped that the postcard was an apt comparison for
          what I was trying to achieve with the website. A communication from
          one person to another, from one place to another, across vast
          distances. Postcards are a form of correspondence that, in the age of
          instant communication, takes real effort.
        </p>

        <p> This website is dedicated to Esther.</p>{" "}
        <Link to="/">
          <h3 className="inspiration_return">return</h3>
        </Link>
      </div>
    </section>
  );
}
